export interface Time{
    h: number,
    m: number,
}

/**
 * Format z.B. 1;20 (h;m)
 */
export const TimeService = {

    toString(h: number, m: number): string {
        return `${h}:${m}`;
    },

    toTime(time: string): Time {
        const timeParts: string[] = time.split(":");
        return {h: Number(timeParts[0]), m: Number(timeParts[1])}
    }

}
