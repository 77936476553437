import React, {FunctionComponent, useEffect, useState} from "react";
import "./Kochbuch.css";
import {ReceiptTO} from "./data/ReceiptTO";
import {ReceiptForm} from "./pages/receiptlist/receipt/ReceiptForm";
import {ReceiptService} from "./services/ReceiptService";
import {View} from "./enums/Views";
import {Receipt} from "./pages/receiptlist/receipt/Receipt";
import {Menu} from "./pages/menu/Menu";
import {Login} from "./pages/login/Login";
import {Random} from "./pages/random/Random";
import {ReceiptListPage} from "./pages/receiptlist/ReceiptListPage";
import {LocalStorageService} from "./services/LocalStorageService";
import {LoginService} from "./services/LoginService";
import {useSearchParams} from 'react-router-dom';

interface KochbuchProps {
}

export const RECEIPT_QUERY_PARAM: string = "receiptID";

export const Kochbuch: FunctionComponent<KochbuchProps> = () => {
    const [receipts, setReceipts] = useState<ReceiptTO[]>([]);
    const [receiptToEdit, setReceiptToEdit] = useState<ReceiptTO | null>(null);
    const [view, setView] = useState<View>(View.list);
    const [searchParameters, setSearchParameters] = useSearchParams();


    useEffect(() => {
        const receiptUrlId: string | null = searchParameters.get(RECEIPT_QUERY_PARAM);
        if (receiptUrlId !== null) {
            const receiptId: number = parseInt(receiptUrlId);
            const receipt: ReceiptTO | undefined = receipts.find((receipt: ReceiptTO): boolean => receipt.id === receiptId);
            if (receipt !== undefined) {
                setReceiptToEdit(receipt);
                setView(View.receipt);
            }
        }
    }, [receipts, searchParameters]);

    const setViewInterceptor = (view: View): void => {
        if (view !== View.receipt){
            clearSearchParams();
        }
        setView(view);
    }

    const clearSearchParams = () => {
            let param: URLSearchParams = new URLSearchParams();
            setSearchParameters(param);
    }

    const addReceipt = (receipt?: ReceiptTO): void => {
        setViewInterceptor(View.list);
        if (receipt !== null && receipt !== undefined) {
            loadReceipts();
        }
        setReceiptToEdit(null);
    };

    const loadReceipts = (): void => {
        setReceipts([]);
        ReceiptService.getReceipts(setReceipts);
    };


    function findReceipt(id: number) {
        return receipts.find((receipt: ReceiptTO): boolean => receipt.id === id);
    }

    function setViewReceipt(receiptToEdit: ReceiptTO | undefined) {
        if (receiptToEdit) {
            setReceiptToEdit(receiptToEdit);
            setViewInterceptor(View.receipt);
        }
    }

    const viewReceipt = (id: number): void => {
        const receiptToEdit = findReceipt(id);
        setViewReceipt(receiptToEdit);
        let param: URLSearchParams = new URLSearchParams();
        param.set("receiptID", id.toString());
        setSearchParameters(param);
    };

    const editReceipt = (id: number): void => {
        const receiptToEdit: ReceiptTO | undefined = receipts.find(
            (receipt: ReceiptTO): boolean => receipt.id === id
        );
        if (receiptToEdit) {
            setReceiptToEdit(receiptToEdit);
            const authenticationHeader: string = LocalStorageService.loadLoginData();
            LoginService.loginWithAuthHeader(
                authenticationHeader,
                () => setViewInterceptor(View.form),
                () => setViewInterceptor(View.login)
            );
        }
    };

    const onCancel = (): void => {
        setViewInterceptor(View.list);
        setReceiptToEdit(null);
    };

    const openEditReceipt = (): void => {
        setViewInterceptor(View.form);
    };

    const list: React.JSX.Element = React.useMemo(
        () => (
            <ReceiptListPage
                receipts={receipts}
                reload={loadReceipts}
                addReceipt={openEditReceipt}
                showReceipt={viewReceipt}
                headerClick={() => setViewInterceptor(View.menu)}
            />
        ),
        // eslint-disable-next-line
        [receipts]
    );

    useEffect((): void => {
        loadReceipts();
    }, []);

    const openDraft = (): void => {
        const draft: ReceiptTO = LocalStorageService.loadDraft();
        if (null !== draft) {
            setReceiptToEdit(draft);
            setViewInterceptor(View.form);
        }
    }

    return (
        <>
            {view === View.receipt && receiptToEdit !== null && (
                <Receipt key={receiptToEdit.id} receipt={receiptToEdit} onCancel={onCancel} onEdit={editReceipt}/>)}
            {view === View.form && (<ReceiptForm onClose={addReceipt} receipt={receiptToEdit}/>)}
            {view === View.menu && (
                <Menu onCloseCallback={() => setViewInterceptor(View.list)} openRandomCallback={() => setViewInterceptor(View.random)} openDraft={() => openDraft()}/>)}
            {view === View.login && (<Login cancelCallback={onCancel} onSuccessfulLoginCallback={openEditReceipt}/>)}
            {view === View.random && (<Random receipts={receipts} setReceiptToEdit={setReceiptToEdit} showReceipt={() => setViewInterceptor(View.receipt)}
                closeRandom={() => setViewInterceptor(View.list)}/>)}
            {list}
        </>
    );
};
