import React, {FunctionComponent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";

interface EditButtonProps {
    onClick: () => void
}

export const EditButton: FunctionComponent<EditButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <button onClick={onClick}>
            <FontAwesomeIcon icon={faPen}/>
        </button>
    );
};
