import React, {FunctionComponent} from "react";

interface CameraProps {
    imageToReturn: (image: string) => void;
    fileToReturn: (file: File) => void;
}

export const Camera: FunctionComponent<CameraProps> = (props) => {

    const {imageToReturn, fileToReturn} = props;

    const handleCapture = (target: FileList | null) => {
        if (target !== null && target !== undefined) {
            const file: File = target[0];

            if (file.type === "image/jpeg" || file.type === "image/png") {


                const fileReader: FileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = function (event) {
                    const imgElement: HTMLImageElement = document.createElement("img");
                    if (event.target) {
                        if (typeof event.target.result === "string") {
                            imgElement.src = event.target.result;
                        }
                    }

                    imgElement.onload = function (e: any) {
                        const canvas: HTMLCanvasElement = document.createElement("canvas");
                        const MAX_WIDTH: number = 500;

                        if (e.target) {
                            const scaleSize: number = MAX_WIDTH / e.target!.width;
                            canvas.width = MAX_WIDTH;
                            canvas.height = e.target!.height * scaleSize;

                            const cxt = canvas.getContext("2d");

                            if (cxt) {

                                cxt.drawImage(e.target, 0, 0, canvas.width, canvas.height);

                                const srcEncoded = cxt.canvas.toDataURL(e.target, "image/jpeg");
                                imageToReturn(srcEncoded);

                                srcToFile(srcEncoded, file.name, file.type).then(newFile => fileToReturn(newFile)).catch(error => console.warn(error));

                            }
                        }
                    }
                }

            }
        }
    }

    function srcToFile(src: string, fileName: string, mimeType: string) {
        return (fetch(src)
                .then(function (res) {
                    return res.arrayBuffer();
                })
                .then(function (buf) {
                    return new File([buf], fileName, {type: mimeType});
                })
        );
    }


    return (
        <div>
            <input id={"cameraButton"} type={"file"} accept="image/png, image/jpeg"
                onChange={(e) => handleCapture(e.target.files)}/>
        </div>
    );
};
