import {FunctionComponent, KeyboardEvent, useState} from "react";
import {Header} from "../../components/common/header/Header";
import {ReceiptTO} from "../../data/ReceiptTO";
import {ReceiptListView} from "../receiptlist/listView/ReceiptListView";
import "./Random.css";

interface RandomProps {
    receipts: ReceiptTO[];
    setReceiptToEdit: (receipt: ReceiptTO) => void;
    showReceipt: () => void;
    closeRandom: () => void;
}

export const Random: FunctionComponent<RandomProps> = (props) => {
    const {receipts, setReceiptToEdit, showReceipt, closeRandom} = props;

    const [bestof, setBestof] = useState<number | undefined>(undefined);
    const [receiptsToShow, setReceiptsToShow] = useState<ReceiptTO[]>([]);

    const viewReceipt = (id: number) => {
        const receiptToEdit: ReceiptTO | undefined = receipts.find(
            (receipt) => receipt.id === id
        );
        if (receiptToEdit) {
            setReceiptToEdit(receiptToEdit);
            showReceipt();
        }
    };

    function getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }

    const isInIndexList = (num: number, indexList: number[]): boolean => {
        let isIn = false;
        for (const element of indexList) {
            if (element === num) {
                isIn = true;
            }
        }
        return isIn;
    };

    const getRandomReceipts = () => {
        const show: ReceiptTO[] = [];

        const randomIndexes: number[] = [];

        if (bestof !== undefined) {
            for (let i = 0; i < bestof; i++) {
                let max: number = receipts.length - i;
                let receiptIndex: number = getRandomInt(max);
                while (isInIndexList(receiptIndex, randomIndexes)) {
                    receiptIndex = getRandomInt(max);
                }
                randomIndexes.push(receiptIndex);
            }
        }

        randomIndexes.forEach((index) => show.push(receipts[index]));

        setReceiptsToShow(show);
    };

    const parsStringToNumberOrUndefind = (
        stringNumber: string
    ): number | undefined => {
        const num: number = Number(stringNumber);
        if (num === 0) {
            return undefined;
        } else {
            return num;
        }
    };

    const onEnter = (event: KeyboardEvent) => {
        if(event.key === "Enter"){
            getRandomReceipts();
        }
    }

    return (
        <>
            <Header onHeaderClick={closeRandom} text={"Random"}/>
            <div className="random-page">
                <label>Wie viele zufällige Rezepte möchtest du haben?</label>

                <div className="flex-spacebetween-aligncenter">
                    <input
                        value={bestof}
                        onChange={(event) =>
                            setBestof(parsStringToNumberOrUndefind(event.target.value))
                        }
                        type="number"
                        className="input-number"
                        onKeyDown={onEnter}
                    />

                    <button onClick={getRandomReceipts}>Random</button>
                </div>

                <ReceiptListView
                    receipts={receiptsToShow}
                    filter={""}
                    placeholder={"Enter Random Number and click Random"}
                    showReceipt={viewReceipt}
                />
            </div>
        </>
    );
};
