import React, {FunctionComponent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

interface SpinnerProps {
}

export const Spinner: FunctionComponent<SpinnerProps> = () => {

    return (
        <div className={"modal"}>
            <div className={"loader"}>
                <FontAwesomeIcon icon={faSpinner} size={"4x"} style={{color: "var(--button-text-color)"}}/>
            </div>
        </div>
    );
}
