import React, {FunctionComponent} from "react";

interface ReceiptLineDividerProps {

}

export const ReceiptLineDivider: FunctionComponent<ReceiptLineDividerProps> = () => {

    return (
        <div className={"divider"}/>
    );
};
