export const colors = {
    primary: "#dde4e7",
    secondary: "#1698F5",
    dark: "black",
    gray: "#888888",
    save: "#2e692e",
    cancel: "#8d2f2f",
    textColor: "white",
    header: "#1698F5",
    h : "black",
}