import {Difficulty} from "../pages/receiptlist/receipt/enums/Difficulty";

export interface ReceiptTO {
    id: number,
    name: string,
    image: string,
    description: string,
    stars: number,
    time: string,
    difficulty: Difficulty,
    ingredients: string[],
    preparation: string,
    tags: string[]
}

export const copyReceiptTO = (receipt: ReceiptTO): ReceiptTO => {
    return {
        id: receipt.id,
        name: receipt.name,
        image: receipt.image,
        description: receipt.description,
        stars: receipt.stars,
        time: receipt.time,
        difficulty: receipt.difficulty,
        ingredients: receipt.ingredients,
        preparation: receipt.preparation,
        tags: receipt.tags
    }
}
