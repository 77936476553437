import React, { FunctionComponent } from "react";

interface MenuButtonProps {
    onClick: () => void;
    children?: React.ReactNode;
    disabled?: boolean;
}

export const MenuButton: FunctionComponent<MenuButtonProps> = (props) => {
    const { onClick, children, disabled } = props;

    return (
        <button className={"menuButton"} onClick={onClick} disabled={disabled ? disabled : false}>{children}</button>
    );
};
