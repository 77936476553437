import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./Login.css";
import { LoginService } from "../../services/LoginService";
import { Spinner } from "../../components/common/Spinner";
import {LoginButton} from './component/LoginButton';

interface LoginProps {
  cancelCallback: () => void;
  onSuccessfulLoginCallback: () => void;
}

export const Login: FunctionComponent<LoginProps> = (props) => {
  const { cancelCallback, onSuccessfulLoginCallback } = props;

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const login = useCallback(() => {
    LoginService.login(
      email,
      password,
      () => {
        setShowSpinner(false);
        onSuccessfulLoginCallback();
      },
      (error) => {
        setShowSpinner(false);
        setMessage(error);
        setPassword("");
      }
    );
  }, [email, onSuccessfulLoginCallback, password]);

  useEffect(() => {
    function downHandler(event: KeyboardEvent) {
      if (event.key === "Enter") {
        login();
      }
    }

    window.addEventListener("keydown", downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [login]); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div className="login">
      {showSpinner && <Spinner />}
      {!showSpinner && (
          <div className="login-content">
            {message !== "" && <label className={"message"}>{message}</label>}
            <input
              type={"text"}
              value={email}
              onChange={(value) => setEmail(value.target.value)}
              autoFocus={true}
            />
            <label>Email</label>
            <input
              type={"password"}
              value={password}
              onChange={(value) => setPassword(value.target.value)}
            />
            <label>Password</label>

            <div className={"buttonGroup"}>
              <button onClick={cancelCallback}>Cancel</button>
              <LoginButton onClick={login}/>
            </div>
          </div>
      )}
    </div>
  );
};
