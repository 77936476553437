import React, {FunctionComponent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

interface DeleteButtonProps {
    onClick: () => void;
}

export const DeleteButton: FunctionComponent<DeleteButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <button onClick={onClick}>
            <FontAwesomeIcon icon={faTrash} color={"red"}/>
        </button>
    );
};
