import axios from "axios";
import {API_URL, FILE_HEADER, FILE_UPLOAD_URL} from "../Properties";

export const FileService = {

    upload(file: File, receiptId: number) {
        let formData: FormData = new FormData();

        formData.append(FILE_HEADER, file);

        return axios.post(API_URL + FILE_UPLOAD_URL + receiptId, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
}
