import React, { FunctionComponent } from "react";

interface ImageViewProps {
    image?: string
}

export const ImageView: FunctionComponent<ImageViewProps> = (props) => {
    const { image } = props;

    return (
        <img className={"receiptImage"} src={image} alt={"Bild"}/>
    );
};
