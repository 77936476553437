// Prod URL

// Test URL
// export const API_URL: string = "http://localhost:8080/receipt/";

export const FILE_UPLOAD_URL: string = "image/";
export const FILE_DOWNLOAD_URL: string = "getImage/";
export const FILE_HEADER: string = "imageFile";
// App Version
export const APP_VERSION: string = "2.5.2";

export const API_URL: string = "https://api.codingmonkey.space/kochbuch/receipt/";
