import React, {FunctionComponent} from 'react';

interface LoginButtonProps {
    onClick: () => void;
}

export const LoginButton: FunctionComponent<LoginButtonProps> = (props) => {
    const {onClick} = props;

    return (
        <button className="login-button" onClick={onClick}>
            Login
        </button>
    );
};