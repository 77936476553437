import React, {FunctionComponent} from "react";
import "./TextInput.css";

export type TextInputProps = {
    type?: string
    className?: string
    value?: string
    onChange: (text: string) => void
    keyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

export const TextInput: FunctionComponent<TextInputProps> = (props) => {

    const {type, className, value, onChange, keyDown} = props;

    return (
        <input
            type={type ? type : "text"}
            className={className ? className : "kochbuch-text-input"}
            value={value}
            onChange={value => onChange(value.target.value)}
            onKeyDown={keyDown ? keyDown : undefined}
        />
    );
};
