const IMAGE_URL: string = "http://images.codingmonkey.space/";

export const ImageService = {

    getImageUrl(imageName: string): string {
        let imageUrl: string = "";
        if (imageName !== null && imageName !== "" && imageName !== undefined) {
            imageUrl = IMAGE_URL + imageName;
        }
        return imageUrl;
    }

}