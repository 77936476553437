import axios from "axios";
import {ReceiptTO} from "../data/ReceiptTO";
import {API_URL} from '../Properties';


const SEND_RECEIPT_URL: string = "receipt/";
const GET_RECEIPTS_URL: string = "receipts/";

export const ReceiptService = {


    sendReceipt(receipt: ReceiptTO, successCallback: () => void, errorCallback: (warning: string) => void) {
        axios.post(API_URL + SEND_RECEIPT_URL, receipt, {
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        },).then(response => {
            if(response.status === 200){
                successCallback();
            }else{
                errorCallback(response.statusText);
            }
        }).catch(error => errorCallback(error.text))
    },

    getReceipts(setReceiptsCallback: (receipts: ReceiptTO[]) => void): void {
        axios.get(API_URL + GET_RECEIPTS_URL, {
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
        })
            .then(response => setReceiptsCallback(response.data))
            .catch(error => console.warn(error));
    },
}
