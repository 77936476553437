import React, {FunctionComponent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";

interface BackButtonProps {
    onClick: () => void;
}

export const BackButton: FunctionComponent<BackButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <button className={"backButton"} onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft}/>
        </button>
    );
};
