import React, {FunctionComponent} from "react";
import {ImageView} from "../../../components/common/ImageView";
import {ImageBox} from "./fragments/ImageBox";
import "./ReceiptListElement.css";
import {ImageService} from '../../../services/ImageService';

interface ReceiptListElementProps {
    name: string;
    image: string;
    onClick: () => void;
    id: number
}

export const ReceiptListElement: FunctionComponent<ReceiptListElementProps> = (props) => {
    const {name, image, onClick, id} = props;

    return (
        <div className="receipt-list-element" onClick={onClick} key={id}>

            <ImageBox>
                <ImageView image={ImageService.getImageUrl(image)}/>
            </ImageBox>

            <div className={"text-receipt-wrapper"}>
                <label className="receipt-name">{name}</label>
            </div>

        </div>
    );
};
