import {ReceiptLine} from "./fragments/ReceiptLine";
import React, {FunctionComponent, useEffect, useState} from "react";
import {copyReceiptTO, ReceiptTO} from "../../../data/ReceiptTO";
import {Difficulty} from "./enums/Difficulty";
import {CancelButton} from "../../../components/common/buttons/CancelButton";
import {SaveButton} from "../../../components/common/buttons/SaveButton";
import {AddButton} from "../../../components/common/buttons/AddButton";
import {ReceiptLineDivider} from "./fragments/ReceiptLineDivider";
import {Camera} from "../../../components/common/Camera";
import {ImageView} from "../../../components/common/ImageView";
import {DeleteButton} from "../../../components/common/buttons/DeleteButton";
import {TimeService} from "../../../services/TimeService";
import {ReceiptService} from "../../../services/ReceiptService";
import {FileService} from "../../../services/FileService";
import {Header} from "../../../components/common/header/Header";
import {Spinner} from "../../../components/common/Spinner";
import {ImageBox} from "./fragments/ImageBox";
import {ImageService} from '../../../services/ImageService';
import "./ReceiptForm.css";
import {TextInput} from '../../../components/common/TextInput';
import {LocalStorageService} from '../../../services/LocalStorageService';

interface ReceiptFormProps {
    onClose: (receipt?: ReceiptTO) => void;
    receipt?: ReceiptTO | null;
}

export const ReceiptForm: FunctionComponent<ReceiptFormProps> = (props) => {
    const {onClose, receipt} = props;

    const [id, setId] = useState<number>(-1);
    const [name, setName] = useState<string>("");
    const [image, setImage] = useState<string>("");
    const [file, setFile] = useState<File>();
    const [description, setDescription] = useState<string>("");
    const [h, setH] = useState<number>(0);
    const [m, setM] = useState<number>(0);
    const [difficulty, setDifficulty] = useState<Difficulty>(Difficulty.easy);
    const [preparation, setPreparation] = useState<string>("");
    const [ingredients, setIngredients] = useState<string[]>([])
    const [newIngredient, setNewIngredient] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [newTag, setNewTag] = useState<string>("");
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState(false);
    const [receiptUpload, setReceiptUpload] = useState(false);

    useEffect(() => {
        if (receipt !== null && receipt !== undefined) {
            setId(receipt.id);
            setName(receipt.name);
            setImage(receipt.image);
            setDescription(receipt.description);
            setH(TimeService.toTime(receipt.time).h);
            setM(TimeService.toTime(receipt.time).m);
            setDifficulty(receipt.difficulty);
            setPreparation(receipt.preparation);
            setIngredients(receipt.ingredients);
            setImage(receipt.image);
            setTags(receipt.tags);
        }

    }, [receipt])

    const onCancel = () => {
        onClose();
    }

    const getImageName = (): string => {
        let imageName: string;
        if (file) {
            imageName = file.name;
        } else {
            imageName = receipt?.image || "";
        }
        return imageName;
    }

    const clearFields = () => {
        setId(-1);
        setName("");
        setImage("");
        setFile(undefined);
        setDescription("");
        setH(0);
        setM(0);
        setDifficulty(Difficulty.easy);
        setPreparation("");
        setIngredients([]);
        setNewIngredient("");
    }

    const onSave = (): void => {
        setShowSpinner(true);
        // save draft
        LocalStorageService.saveDraft(JSON.stringify({
            name: name,
            description: description,
            difficulty: difficulty,
            id: id,
            image: getImageName(),
            stars: 0,
            ingredients: ingredients,
            preparation: preparation,
            time: TimeService.toString(h, m),
            tags: tags
        }));

        const maxLengthIngredients: number = 10000;
        // validate length of ingredients
        if (ingredients.length > maxLengthIngredients) {
            window.alert("Dein Rezept hat zu viel Zutaten! \n Maximal Zeichen sind: " + maxLengthIngredients);
            setFileUpload(false);
            setShowSpinner(false);
            return;
        }

        if (file !== null && file !== undefined) {
            setFileUpload(true);
            FileService.upload(file, id).then((): void => {
                setFileUpload(false);
                closeAfterUpload();
                // upload receipt
                setReceiptUpload(true);
                ReceiptService.sendReceipt({
                    name: name,
                    description: description,
                    difficulty: difficulty,
                    id: id,
                    image: getImageName(),
                    stars: 0,
                    ingredients: ingredients,
                    preparation: preparation,
                    time: TimeService.toString(h, m),
                    tags: tags
                }, successReceiptUpload, errorReceiptUpload)
            }).catch(error => {
                window.alert("Ups! Beim Bild upload ist was schiefgegangen 😬 \n Bitte versuch es gleich nochmal 😇 \n" + error);
                setFileUpload(false);
                setShowSpinner(false);
            });
        }

    }

    const successReceiptUpload = (): void => {
        LocalStorageService.clearDraft();
        setReceiptUpload(false);
        closeAfterUpload();
        window.alert("Rezept erfolgreich gespeichert 🥳")
    }

    const errorReceiptUpload = (error: string): void => {
        window.alert("Ups! Beim Rezept upload ist was schiefgegangen 😬 \n Bitte versuch es gleich nochmal 😇 \n" + error);
        setReceiptUpload(false);
        setShowSpinner(false);
    }

    const closeAfterUpload = (): void => {
        if (!fileUpload && !receiptUpload) {
            setShowSpinner(false);
            clearFields();

            const copyReceipt: ReceiptTO = copyReceiptTO({
                name: name,
                description: description,
                difficulty: difficulty,
                id: id,
                image: image,
                ingredients: ingredients,
                preparation: preparation,
                stars: 0,
                time: TimeService.toString(h, m),
                tags: tags
            });

            onClose(copyReceipt)
        }
    }

    const addIngredient = () => {
        const copyIngredients = ingredients;
        copyIngredients.push(newIngredient);
        setNewIngredient("");
        setIngredients(copyIngredients);
    }

    const addTag = () => {
        const copyTags = tags;
        copyTags.push(newTag);
        setNewTag("");
        setTags(copyTags);
    }


    return (
        <div className="receipt-form">
            <Header buttonRight={<SaveButton onClick={onSave}/>} buttonLeft={<CancelButton onClick={onCancel}/>}/>
            <div className={"receipt-form-content"}>

                {showSpinner && <Spinner/>}

                <ReceiptLine>
                    <h3 className="form-title">Name</h3>
                </ReceiptLine>

                <ReceiptLine>
                    <TextInput onChange={setName} value={name}/>
                </ReceiptLine>

                <ReceiptLineDivider/>

                <ReceiptLine className={"flexSpaceAroundCenter"}>
                    <ImageBox>
                        <ImageView image={ImageService.getImageUrl(image)}/>
                    </ImageBox>
                </ReceiptLine>
                <Camera imageToReturn={setImage} fileToReturn={setFile}/>
                <ReceiptLine>

                </ReceiptLine>

                <ReceiptLineDivider/>

                <ReceiptLine style={{justifyContent: "space-between"}}>
                    <h3 className="form-title">Tags</h3>
                </ReceiptLine>
                <ReceiptLine>
                    <TextInput onChange={setNewTag} value={newTag} keyDown={keyEvent => {
                        if (keyEvent.key === 'Enter') {
                            addTag();
                        }
                    }}/>
                    <AddButton onClick={addTag}/>
                </ReceiptLine>

                <ReceiptLine>
                    <ul className="receipt-form-list">
                        {tags.map((tag, index) => <li key={index}>{tag}
                            <DeleteButton onClick={() => {
                                let copyTags = tags;
                                copyTags = copyTags.filter((t) => t !== tag);
                                setTags(copyTags);
                            }
                            }/>
                        </li>)}
                    </ul>
                </ReceiptLine>

                <ReceiptLineDivider/>

                <ReceiptLine>
                    <h3 className="form-title">Zeit</h3>
                </ReceiptLine>
                <ReceiptLine className={"flexSpaceAroundCenter"}>
                    <ReceiptLine>
                        <input type="number"
                            className={"border"}
                            style={{width: "2rem"}}
                            value={h === 0 ? undefined : h}
                            onChange={value => setH(Number(value.target.value))}/><h1>h</h1>
                    </ReceiptLine>
                    <ReceiptLine>
                        <input
                            type="number"
                            className={"border"}
                            style={{width: "2rem"}} value={m === 0 ? undefined : m}
                            onChange={value => setM(Number(value.target.value))}
                            max={"59"}/><h1>m</h1>
                    </ReceiptLine>
                </ReceiptLine>

                <ReceiptLineDivider/>

                <ReceiptLine>
                    <h3 className="form-title">difficulty</h3>
                </ReceiptLine>
                <ReceiptLine className={"flexSpaceAroundCenter"}>
                    <button className={"selected"} disabled={difficulty === Difficulty.easy}
                        onClick={() => setDifficulty(Difficulty.easy)}>{Difficulty.easy.toUpperCase()}</button>
                    <button className={"selected"} disabled={difficulty === Difficulty.middle}
                        onClick={() => setDifficulty(Difficulty.middle)}>{Difficulty.middle.toUpperCase()}</button>
                    <button className={"selected"} disabled={difficulty === Difficulty.hard}
                        onClick={() => setDifficulty(Difficulty.hard)}>{Difficulty.hard.toUpperCase()}</button>
                </ReceiptLine>

                <ReceiptLineDivider/>

                <ReceiptLine style={{justifyContent: "space-between"}}>
                    <h3 className="form-title">Zutaten</h3>
                </ReceiptLine>
                <ReceiptLine>
                    <TextInput onChange={setNewIngredient} value={newIngredient} keyDown={keyEvent => {
                        if (keyEvent.key === 'Enter') {
                            addIngredient();
                        }
                    }}/>
                    <AddButton onClick={addIngredient}/>
                </ReceiptLine>

                <ReceiptLine>
                    <ul className="receipt-form-list">
                        {ingredients.map((ingredient, index) => <li key={index}>{ingredient}
                            <DeleteButton onClick={() => {
                                let copyIngredients = ingredients;
                                copyIngredients = copyIngredients.filter((ingred) => ingred !== ingredient);
                                setIngredients(copyIngredients);
                            }
                            }/>
                        </li>)}
                    </ul>
                </ReceiptLine>

                <ReceiptLineDivider/>

                <ReceiptLine>
                    <h3 className="form-title">Zubereitung</h3> <label>{4000 - preparation.length}</label>
                </ReceiptLine>
                <ReceiptLine>
                <textarea className="preparation-text" value={preparation}
                    onChange={value => setPreparation(value.target.value)}/>
                </ReceiptLine>
            </div>
        </div>
    );
}
