import React, {CSSProperties, FunctionComponent} from "react";
import "./ReceiptLine.css";

interface ReceiptLineProps {
    className?: string;
    style?: CSSProperties
    children?: React.ReactNode
}

export const ReceiptLine: FunctionComponent<ReceiptLineProps> = (props) => {
    const { children, className, style } = props;

    return (
        <div className={className ? className : "receiptLine"} style={style}>
            {children}
        </div>
    );
};
