import {ReceiptTO} from '../data/ReceiptTO';

const LOGIN_DATA_KEY: string = "kochbuch";
const DRAFT_KEY: string = "kochbuch-draft";

export const LocalStorageService = {
    saveLoginData(token: string): void {
        localStorage.setItem(LOGIN_DATA_KEY, token);
    },

    loadLoginData(): string {
        const authenticationHeader: string | null = localStorage.getItem(LOGIN_DATA_KEY);
        if (authenticationHeader !== null) {
            return authenticationHeader;
        } else {
            return "";
        }
    },

    saveDraft(jsonDraft: string): void {
        localStorage.setItem(DRAFT_KEY, jsonDraft);
    },

    loadDraft(): ReceiptTO {
        const draft: string | null = localStorage.getItem(DRAFT_KEY)
        if (null !== draft) {
            return JSON.parse(draft);
        } else {
            throw new Error("No draft saved");
        }
    },

    clearDraft(): void {
        localStorage.removeItem(DRAFT_KEY);
    },

    hasDraft(): boolean {
        const draft: string | null = localStorage.getItem(DRAFT_KEY)
        return null !== draft;
    }
}