import React, {FunctionComponent} from "react";

interface PlaceholderProps {
    text: string
}

export const Placeholder: FunctionComponent<PlaceholderProps> = (props) => {
    const {text} = props;

    return (
        <div className={"flexColumnCenterMaxSize"}><h3>{text}</h3></div>
    );
};
