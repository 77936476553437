import { LocalStorageService } from "./LocalStorageService";

const SERVER_ADDRESS: string = "https://api.codingmonkey.space/kerberos/user/login";

const createEncodedAuthHeader = (email: string, password: string) => {
  return btoa(email + ":" + password);
};

export const LoginService = {
  loginWithAuthHeader(
    authHeader: string,
    successCallback: (message: string) => void,
    errorCallback: (error: string) => void
  ) {
    let statusOK: boolean = false;

    fetch(SERVER_ADDRESS, {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    })
      .then((response) => {
        statusOK = response.ok;
        return response.text();
      })
      .then((body) => {
        if (statusOK) {
          LocalStorageService.saveLoginData(authHeader);
          successCallback(body);
        } else {
          errorCallback(body);
        }
      })
      .catch((error) => errorCallback(error));
  },
  login(
    email: string,
    password: string,
    successCallback: (message: string) => void,
    errorCallback: (error: string) => void
  ) {
    const authHeader: string = createEncodedAuthHeader(email, password);
    this.loginWithAuthHeader(authHeader, successCallback, errorCallback);
  },


};
