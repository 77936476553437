import {faSearch, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FunctionComponent, KeyboardEvent, useState} from "react";
import {AddButton} from "../../components/common/buttons/AddButton";
import {ReloadButton} from "../../components/common/buttons/ReloadButton";
import {Header} from "../../components/common/header/Header";
import {Spinner} from "../../components/common/Spinner";
import {ReceiptTO} from "../../data/ReceiptTO";
import {ReceiptListView} from "./listView/ReceiptListView";
import {ReceiptLine} from "./receipt/fragments/ReceiptLine";
import "./ReceiptListPage.css";

interface ReceiptListPageProps {
    receipts: ReceiptTO[];
    reload: () => void;
    addReceipt: () => void;
    showReceipt: (id: number) => void;
    headerClick: () => void;
}

export const ReceiptListPage: FunctionComponent<ReceiptListPageProps> = (props) => {
    const {receipts, reload, addReceipt, showReceipt, headerClick} = props;

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");

    const onReload = () => {
        reload();
        setShowSpinner(true);
        setTimeout(() => setShowSpinner(false), 1400);
    };

    const handleKeyDown = (event: KeyboardEvent): void => {
        if(event.key === 'Escape'){
            clearFilter();
        }
    }

    const clearFilter = (): void => {
        setFilter("");
    }

    return (
        <div className="receipt-list-page">
            <Header
                onHeaderClick={headerClick}
                buttonLeft={<ReloadButton onClick={onReload}/>}
                buttonRight={<AddButton onClick={addReceipt}/>}
            />

            {showSpinner && <Spinner/>}

            <ReceiptLine>
                <div className="border flexSpaceBetween search-div">
                    <input
                        type={"text"}
                        className={"search-content"}
                        value={filter}
                        onChange={(value) => setFilter(value.target.value)}
                        placeholder={"Search..."}
                        onKeyDown={handleKeyDown}
                    />

                    {filter.length > 0 && (
                        <button className={"clear-button"} onClick={() => clearFilter()}>
                            <FontAwesomeIcon icon={faTimesCircle}/>
                        </button>
                    )}
                    <FontAwesomeIcon icon={faSearch} className={"search-icon"}/>
                </div>
            </ReceiptLine>

            <ReceiptListView
                receipts={receipts}
                showReceipt={showReceipt}
                filter={filter}
                placeholder="'Add' button ;-)"
            />
        </div>
    );
};
