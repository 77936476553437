import React, {FunctionComponent} from "react";
import shareIcon from "../../../icons/share-nodes-solid.svg";
import "./ShareButton.css";

export type ShareButtonProps = {
    onClick: () => void;
}

export const ShareButton: FunctionComponent<ShareButtonProps> = (props) => {

    const {onClick} = props;

    return (
        <button onClick={() => onClick()}>
            <img className="share-button-icon" src={shareIcon} alt="shareIcon"/>
        </button>
        );
};
