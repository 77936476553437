import React, { FunctionComponent } from "react";

interface HeaderButtonProps {
    text?: string
    onClick?: () => void;
}

export const HeaderButton: FunctionComponent<HeaderButtonProps> = (props) => {
    const { text, onClick } = props;

    return (
        <button className={"headerButton"} onClick={onClick}>
            <h1>{text}</h1>
        </button>
    );
};
