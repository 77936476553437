import React, {FunctionComponent} from "react";
import {Placeholder} from "../../../components/common/Placeholder";
import {ReceiptTO} from "../../../data/ReceiptTO";
import {ReceiptListElement} from "../receipt/ReceiptListElement";
import "./ReceiptListView.css";

interface ReceiptListViewProps {
    receipts: ReceiptTO[];
    showReceipt: (id: number) => void;
    filter: string;
    placeholder: string;
}

export const ReceiptListView: FunctionComponent<ReceiptListViewProps> = (props: ReceiptListViewProps) => {
    const {receipts, showReceipt, filter, placeholder} = props;

    const buildReceiptListElement = (receiptTO: ReceiptTO, index: number): React.JSX.Element => {
        return (
            <ReceiptListElement
                key={index}
                id={index}
                name={receiptTO.name}
                image={receiptTO.image}
                onClick={() => showReceipt(receiptTO.id)}
            />
        );
    };

    const receiptNotContainsFilter = (receipt: ReceiptTO): boolean => {
        let contains: boolean[] = [];
        if (filter !== "") {
            let filters: string[] = filter.split(" ");
            filters.forEach(filter => {
                if (filter !== "") {
                    contains.push(receipt.name.toLowerCase().includes(filter.toLowerCase()) || tagsContainsSubString(filter, receipt.tags))
                }
            });
        } else {
            return true;
        }
        return contains.includes(true);
    };

    const tagsContainsSubString = (filter: string, tags: string[]): boolean => {
        let includes: boolean = false;
        tags.forEach(tag => {
            if (tag.toLowerCase().includes(filter.toLowerCase())) {
                includes = true;
            }
        })
        return includes;
    }

    const alphabeticallyReceiptSort = (
        receipt1: ReceiptTO,
        receipt2: ReceiptTO
    ): number => {
        if (receipt1.name < receipt2.name) {
            return -1;
        }
        if (receipt1.name > receipt2.name) {
            return 1;
        }
        return 0;
    };

    return (
        <>
            <label className="receipt-total-label">{receipts.filter(receiptNotContainsFilter).length}</label>
            <div className="receiptListView">
                {receipts
                    .filter(receiptNotContainsFilter)
                    .sort((a, b) => alphabeticallyReceiptSort(a, b))
                    .map((receipt, index) => buildReceiptListElement(receipt, index))}
                {receipts.length === 0 && <Placeholder text={placeholder}/>}
            </div>
        </>
    );
};
