import React, {FunctionComponent} from "react";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {IconLabel} from "../../../components/common/iconlabel/IconLabel";
import {ImageView} from "../../../components/common/ImageView";
import {TimeService} from "../../../services/TimeService";
import {ReceiptTO} from "../../../data/ReceiptTO";
import {Header} from "../../../components/common/header/Header";
import {EditButton} from "../../../components/common/buttons/EditButton";
import {BackButton} from "../../../components/common/buttons/BackButton";
import "./Receipt.css";
import {ImageService} from '../../../services/ImageService';
import {colors} from '../../../css/colors';
import {ReceiptLine} from './fragments/ReceiptLine';
import {ShareButton} from '../../../components/common/buttons/ShareButton';


interface ReceiptProps {
    receipt: ReceiptTO
    onCancel: () => void;
    onEdit: (id: number) => void;
}

export const Receipt: FunctionComponent<ReceiptProps> = (props: ReceiptProps) => {
    const {receipt, onCancel, onEdit} = props;

    const buildIngredients = (): React.JSX.Element => {
        return (
            <ul className="liste">
                {receipt.ingredients.map((ingredient, index) => <li key={index}>{ingredient}</li>)}
            </ul>);
    }

    const getUrlFromLocation = (): ShareData => {
        const url: string = window.location.href;
        return {url: url};
    }

    const shareAction = async () => {
        if (document.location.protocol === 'https:') {

            if (navigator.canShare(getUrlFromLocation())) {

                try {
                    await navigator.share(getUrlFromLocation());
                    console.log("share successfull");
                } catch (error) {
                    console.warn(`share failed :-( ${error}`);
                    await navigator.clipboard.writeText(window.location.href);
                    alert("URL in Zwischenspeicher kopiert");
                }
            }
        } else {
            await navigator.clipboard.writeText(window.location.href);
            alert("URL in Zwischenspeicher kopiert");
        }
    }

    return (
        <div className="receipt">
            <Header buttonLeft={<BackButton onClick={onCancel}/>}
                buttonRight={[
                    <EditButton onClick={() => onEdit(receipt.id)}/>,
                    <ShareButton onClick={() => shareAction()}/>
                ]
                }
            />

            <div>
                <div className="image-wrapper">
                    <ImageView image={ImageService.getImageUrl(receipt.image)}/>
                </div>

                <div className="receipt-content">

                    <ReceiptLine>
                        <div className={"text-wrapper"}>
                            <ReceiptLine>
                                <h1>{receipt.name}</h1>
                            </ReceiptLine>

                            <ReceiptLine>
                                <div className={"attribute-wrapper"}>
                                    <IconLabel icon={faClock} text={`${TimeService.toTime(receipt.time).h} H ${TimeService.toTime(receipt.time).m} M`}/>
                                </div>
                            </ReceiptLine>
                        </div>
                    </ReceiptLine>

                    <ReceiptLine>
                        <h3>Zutaten</h3>
                    </ReceiptLine>

                    <ReceiptLine>
                        {buildIngredients()}
                    </ReceiptLine>

                    <ReceiptLine>
                        <h3>Zubereitung</h3>
                    </ReceiptLine>

                    <ReceiptLine>
                        <label className="text-area">{receipt.preparation}</label>
                    </ReceiptLine>
                    <div className={"attribute-wrapper"}>
                        {receipt.tags.map((tag, index) => <IconLabel key={index} text={"#" + tag} color={colors.secondary}/>)}
                    </div>
                </div>


            </div>
        </div>
    );
};
