import React, {FunctionComponent} from "react";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./IconLabel.css";

interface IconLabelProps {
    icon?: IconDefinition;
    text?: string;
    color?: string
}

export const IconLabel: FunctionComponent<IconLabelProps> = (props) => {
    const {icon, text, color} = props;
    const defaultColor = "white";

    return (
        <div className={"iconLabel roundCorners"} style={{backgroundColor: color ? color : defaultColor}}>
            {icon && <FontAwesomeIcon icon={icon} className={"icon"}/>}
            {text && <label className="text">{text}</label>}
        </div>
    );
};
