import React, {FunctionComponent} from "react";
import {HeaderButton} from "./fragments/HeaderButton";
import "./Header.css";

interface HeaderProps {
    text?: string;
    onHeaderClick?: () => void;
    buttonRight?: React.JSX.Element[] | React.JSX.Element
    buttonLeft?: React.JSX.Element
}

export const Header: FunctionComponent<HeaderProps> = (props) => {
    const {buttonLeft, buttonRight, onHeaderClick, text} = props;

    const headerTextDefault: string = "Kochbuch";
    const placeholder: React.JSX.Element = <div/>;

    return (
        <div className={"flexSpaceBetweenCenter header"}>
            {buttonLeft ? buttonLeft : placeholder}
            <HeaderButton text={text ? text : headerTextDefault} onClick={onHeaderClick}/>
            {buttonRight ? buttonRight : placeholder}
        </div>
    );
};
