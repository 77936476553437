import React, { FunctionComponent } from "react";

interface FooterProps {
    children?: React.ReactNode
}

export const Footer: FunctionComponent<FooterProps> = (props) => {
    const { children } = props;

    return (
        <div className={"footer"}>
            {children}
        </div>
    );
};
