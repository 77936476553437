import React, {FunctionComponent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";

interface AddButtonProps {
    onClick: () => void;
}

export const AddButton: FunctionComponent<AddButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <button onClick={onClick}>
            <FontAwesomeIcon icon={faPlus}/>
        </button>
    );
};
