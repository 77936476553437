import React, {FunctionComponent} from "react";
import {APP_VERSION} from "../../Properties";
import {Footer} from "../../components/common/Footer";
import {MenuButton} from "./fragments/MenuButton";
import {LocalStorageService} from '../../services/LocalStorageService';

interface MenuProps {
    onCloseCallback: () => void;
    openRandomCallback: () => void;
    openDraft: () => void;
    children?: React.ReactNode;
}

export const Menu: FunctionComponent<MenuProps> = (props) => {
    const {onCloseCallback, openRandomCallback, openDraft} = props;

    const openDraftIfExist = (): void => {
        if(LocalStorageService.hasDraft()){
            openDraft();
        }
    }

    return (
        <div className={"flexColumnCenterMaxSize"}>
            <MenuButton onClick={onCloseCallback}>Close</MenuButton>
            <MenuButton onClick={() => openDraftIfExist()} disabled={!LocalStorageService.hasDraft()}>Open Draft</MenuButton> <label>{LocalStorageService.hasDraft() ? "1" : ""}</label>
            <MenuButton onClick={openRandomCallback}>Random</MenuButton>
            <Footer>
                <label>V{APP_VERSION}</label>
            </Footer>
        </div>
    );
};
