import React from 'react';
import './index.css';
import {Kochbuch} from './Kochbuch';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {createRoot, Root} from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

const container: HTMLElement | null = document.getElementById('root')
const root: Root = createRoot(container!)
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Kochbuch/>}/>
        </Routes>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();