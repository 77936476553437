import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FunctionComponent} from "react";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import "./SaveButton.css";

interface SaveButtonProps {
    onClick: () => void;
}

export const SaveButton: FunctionComponent<SaveButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <button className={"saveButton"} onClick={onClick}>
            <FontAwesomeIcon icon={faSave} />
        </button>
    );
};
