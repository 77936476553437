import React, {FunctionComponent} from "react";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons/faWindowClose";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./CancelButton.css";

interface CancelButtonProps {
    onClick: () => void;
}

export const CancelButton: FunctionComponent<CancelButtonProps> = (props) => {
    const {onClick} = props;

    return (
        <button className={"cancelButton"} onClick={onClick}>
            <FontAwesomeIcon icon={faWindowClose}/>
        </button>
    );
};
